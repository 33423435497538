<template>
    <Hero>
        <h1>
            Bestätigung erfolgreich!
        </h1>
    </Hero>
    <section>
        <section-spacer :spacer="2" type="gap"></section-spacer>
        <div class="sp"></div>
        <div class="container-fluid text-center">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-9 col-xl-8">
                    <h2>
                      Danke, dass du uns an deiner Erfahrung
                      <span style="white-space: nowrap">teilhaben lässt</span>.
                    </h2>
                    <p>
                      Solltest du eine bereits vorhandene Milch bewertet haben,
                      fließt deine Bewertung nun direkt mit ein,
                      und hilft anderen ihre perfekte Milch zu finden.
                    </p>
                    <p>
                      Handelt es sich um eine neue Milch,
                      welche bisher noch nicht hinterlegt ist,
                      werden wir deine Anfrage so schnell wie möglich überprüfen
                      und die Milch zur Übersicht hinzufügen.
                    </p>
                </div>
            </div>
        </div>
        <div class="sp"></div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Hero from '@/components/Hero.vue';
import SectionSpacer from '@/components/SectionSpacer.vue';

export default defineComponent({
  name: 'ActivatedSubmission',

  components: {
    Hero,
    SectionSpacer,
  },
});
</script>

<style scoped>

</style>
